import { Button, Result } from "antd";
import Layout from "antd/es/layout/layout";
import { logout } from "global/context/user/user.utls";
import "./unauthorizedBusiness.styles.scss";

const UnauthorizedBusiness = () => {
  return (
    <Layout className="u-a-page-wrapper">
      <Result
        status="error"
        title="Login Blocked"
        subTitle="Your business is not registered with us. Please contact admin to make sure we have credentials for your business"
        extra={[
          <Button
            key={"1"}
            type="primary"
            size="large"
            onClick={() => logout()}
          >
            Logout
          </Button>,
        ]}
      />
    </Layout>
  );
};

export default UnauthorizedBusiness;
