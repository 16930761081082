export const formRules = {
  email: [
    {
      required: true,
      message: "This field is required",
    },
  ],
  password: [
    {
      required: true,
      message: "This field is required",
    },
  ],
};
