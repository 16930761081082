import { useForm, useWatch } from "antd/es/form/Form";
import useMessage from "antd/es/message/useMessage";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "firebaseConfig/config";
import { ROLES } from "global/constants";
import { useUser } from "global/context";
import { useEffect, useState } from "react";

const useDashboard = () => {
  const [totalBusinesses, setTotalBusinesses] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [chartData, setChartData] = useState(null);
  const [updatingGlobalVariables, setUpdatingGlobalVariables] = useState(false);
  const [loadingBarChart, setLoadingBarChart] = useState(false);

  const { user } = useUser();

  const [messageApi, messageContext] = useMessage();

  const [globalVariablesForm] = useForm();
  const paypalLink = useWatch("paypalLink", globalVariablesForm);

  useEffect(() => {
    const init = async () => {
      try {
        // fetch total businesses
        setLoadingBarChart(true);
        const businessesSnap = await getDocs(collection(db, "businessOwners"));
        const globalVariablesSnap = await getDocs(
          collection(db, "globalVariables")
        );
        const chartData = [];
        setTotalBusinesses(businessesSnap.size);
        globalVariablesForm.setFieldsValue(globalVariablesSnap.docs[0].data());

        // fetch total customers
        let totalCustomersCount = 0;
        for (let doc of businessesSnap.docs) {
          const name = doc.data().name;
          const customersSnap = await getDocs(collection(doc.ref, "customers"));
          totalCustomersCount += customersSnap.size;
          chartData.push({ name, customersCount: customersSnap.size });
        }
        setChartData(chartData);
        setTotalCustomers(totalCustomersCount);
      } catch (error) {
        console.error("Error during initialization:", error);
        messageApi.error("Failed to initialize dashboard data.");
      } finally {
        setLoadingBarChart(false);
      }
    };

    init();
  }, [globalVariablesForm, messageApi]);

  const onGlobalVariablesFormFinish = async (values) => {
    try {
      setUpdatingGlobalVariables(true);
      const globalVariablesSnap = await getDocs(
        collection(db, "globalVariables")
      );
      if (globalVariablesSnap.empty) {
        // Collection is empty, create new document
        await addDoc(collection(db, "globalVariables"), values);
        messageApi.success("Global variables initialized successfully.");
      } else {
        // Collection exists, update the first document
        const firstDoc = globalVariablesSnap.docs[0];
        await updateDoc(doc(db, "globalVariables", firstDoc.id), values);
        messageApi.success("Global variables updated successfully.");
      }
    } catch (error) {
      console.error("Error in global variables operation:", error);
      messageApi.error("Failed to process global variables.");
    } finally {
      setUpdatingGlobalVariables(false);
    }
  };

  return {
    totalBusinesses,
    totalCustomers,
    chartData,
    globalVariablesForm,
    paypalLink,
    onGlobalVariablesFormFinish,
    messageContext,
    updatingGlobalVariables,
    isAdmin: user.role === ROLES.ADMIN,
    loadingBarChart,
  };
};

export default useDashboard;
