import {
  DownloadOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Empty,
  Select,
  Space,
  Table,
} from "antd";
import { EMPTY_SVG } from "assets";
import { Loader } from "global/components";
import { ACTIONS, MODULES } from "global/constants";
import { WithPermission } from "global/hoc";
import { CouponModal, ManageCustomerDrawer } from "./components";
import useCustomers from "./useCustomers.hook";

const Customers = () => {
  const {
    toggleManageCustomerDrawer,
    isManageCustomerDrawerVisible,
    messageContext,
    customerTableColumns,
    customers,
    loading,
    selectedCustomer,
    businesses,
    handleBusinessSelect,
    selectedBusinessId,
    userRole,
    toggleCouponModal,
    isCouponModalVisible,
    selectedBusiness,
    downloadCSV,
    onRowClick,
    notificationContext,
    isCouponSubExpired,
  } = useCustomers();

  const cardExtra = (
    <Space wrap style={{ justifyContent: "flex-end" }}>
      <WithPermission
        module={MODULES.BUSINESSES}
        role={userRole}
        action={ACTIONS.VIEW}
      >
        <Select
          onSelect={handleBusinessSelect}
          placeholder="Select Business"
          style={{ minWidth: 200 }}
          options={businesses}
          fieldNames={{ label: "name", value: "id" }}
          value={selectedBusinessId}
        />
      </WithPermission>
      <WithPermission
        role={userRole}
        module={MODULES.CUSTOMERS}
        action={ACTIONS.VIEW}
      >
        <Button
          type="primary"
          ghost
          icon={<DownloadOutlined />}
          onClick={downloadCSV}
        >
          Download Csv
        </Button>
      </WithPermission>
      <WithPermission
        role={userRole}
        module={MODULES.COUPONS}
        action={ACTIONS.VIEW}
      >
        <Button
          icon={<MailOutlined />}
          onClick={toggleCouponModal}
          disabled={isCouponSubExpired}
        >
          Send Coupon
        </Button>
      </WithPermission>
      <WithPermission
        type="primary"
        role={userRole}
        module={MODULES.CUSTOMERS}
        action={ACTIONS.UPDATE}
      >
        <Button icon={<PlusOutlined />} onClick={toggleManageCustomerDrawer}>
          Add Customer
        </Button>
      </WithPermission>
    </Space>
  );

  return (
    <>
      {notificationContext}
      {messageContext}
      <CouponModal
        open={isCouponModalVisible}
        handleClose={toggleCouponModal}
        customers={customers}
        businessInfo={selectedBusiness}
      />
      <ManageCustomerDrawer
        open={isManageCustomerDrawerVisible}
        onClose={toggleManageCustomerDrawer}
        selectedCustomer={selectedCustomer}
        businesses={businesses}
      />
      <Card title="CUSTOMERS LIST" className="min-h-100" extra={cardExtra}>
        <ConfigProvider
          renderEmpty={() => <Empty description="Empty" image={EMPTY_SVG} />}
        >
          <Table
            dataSource={customers}
            columns={customerTableColumns}
            bordered={false}
            rowKey={"id"}
            scroll={{ x: "max-content" }}
            loading={{
              indicator: <Loader />,
              spinning: loading,
            }}
            onRow={onRowClick}
          />
        </ConfigProvider>
      </Card>
    </>
  );
};

export default Customers;
