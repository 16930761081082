import { MenuOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Space, Tag, Typography } from "antd";

import { ManageBusinessDrawer } from "pages/businesses/components";
import useHeader from "./useHeader.hook";

const Header = ({ onToggleSider }) => {
  const {
    isManageBusinessDrawerVisible,
    activeHeading,
    menu,
    user,
    handleToggleBusinessDrawer,
    initials,
    roleName,
    screens,
  } = useHeader();

  return (
    <>
      <ManageBusinessDrawer
        selectedBusiness={user}
        open={isManageBusinessDrawerVisible}
        onClose={handleToggleBusinessDrawer}
      />
      <header className="default-layout-header">
        <Space size={12}>
          {!screens.md && (
            <MenuOutlined className="hamburger" onClick={onToggleSider} />
          )}
          <Typography.Title className="m-0" level={3}>
            {activeHeading}
          </Typography.Title>
        </Space>
        <Space size={0}>
          <Tag color="blue" className="role-tag">
            {roleName}
          </Tag>
          <Dropdown menu={{ items: menu }}>
            <Avatar size={"large"} style={{ backgroundColor: "#141414" }}>
              {initials}
            </Avatar>
          </Dropdown>
        </Space>
      </header>
    </>
  );
};

export default Header;
