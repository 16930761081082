import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Space, Table } from "antd";
import useBusinesses from "./useBusinesses.hook";
import { ManageBusinessDrawer } from "./components";
import { Loader } from "global/components";

const Businesses = () => {
  const {
    toggleManageBusinessDrawer,
    isManageBusinessDrawerVisible,
    messageContext,
    businessTableColumns,
    businesses,
    loading,
    selectedBusiness,
    handleAddBusinessRequest,
    downloadCSV,
    onRowClick,
  } = useBusinesses();

  const cardExtra = (
    <Space wrap>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddBusinessRequest}
      >
        Add
      </Button>

      <Button icon={<DownloadOutlined />} onClick={downloadCSV}>
        CSV
      </Button>
    </Space>
  );

  return (
    <>
      {messageContext}
      <ManageBusinessDrawer
        open={isManageBusinessDrawerVisible}
        onClose={toggleManageBusinessDrawer}
        selectedBusiness={selectedBusiness}
      />
      <Card title="BUSINESSES LIST" className="min-h-100" extra={cardExtra}>
        <Table
          dataSource={businesses}
          columns={businessTableColumns}
          bordered={false}
          rowKey={"id"}
          scroll={{ x: "max-content" }}
          loading={{
            indicator: <Loader />,
            spinning: loading,
          }}
          onRow={onRowClick}
        />
      </Card>
    </>
  );
};

export default Businesses;
