import { useForm, useWatch } from "antd/es/form/Form";
import useMessage from "antd/es/message/useMessage";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getDocs,
  or,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig/config";
import { ROLES } from "global/constants";
import { useUser } from "global/context";
import { removeCountryCodeFromStart } from "global/utils";
import { reviewEntryOptions } from "pages/businesses/businesses.constants";
import { useEffect, useState } from "react";

const useManageBusinessDrawer = ({ selectedBusiness, onClose, open }) => {
  const [messageApi, messageContext] = useMessage();

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const [form] = useForm();

  const smsCouponSubService = useWatch("smsCouponSubService", form);
  const reviewEntryUrl2 = useWatch("reviewEntryUrl2", form);
  const reviewEntryUrl4 = useWatch("reviewEntryUrl4", form);
  const reviewEntryUrl3 = useWatch("reviewEntryUrl3", form);
  const reviewEntryUrl = useWatch("reviewEntryUrl", form);

  const { user } = useUser();

  const spaceStyle = { width: "100%", margin: "10px 0 20px" };

  const handleManageBusiness = async (values) => {
    try {
      setLoading(true);
      if (values?.smsCouponSubService)
        values.smsSubExpiry = values?.smsSubExpiry?.toISOString();

      if (values?.lastSendToAllDate)
        values.lastSendToAllDate = values?.lastSendToAllDate?.toISOString();

      if (values?.businessActiveTillDate)
        values.businessActiveTillDate =
          values?.businessActiveTillDate?.toISOString();

      values.phone1 = +`${values.countryCode}${values.phone1}`.substring(1);

      values.phone2 = values.phone2
        ? +`${values.countryCode}${values.phone2}`.substring(1)
        : values.phone2;

      let queryCondition = where("phone1", "==", values?.phone1);
      if (values.phone2) {
        queryCondition = or(
          where("phone1", "==", values?.phone1),
          where("phone2", "==", values?.phone2)
        );
      }
      const duplicateBusiness = await getDocs(
        query(collection(db, "businessOwners"), queryCondition)
      );

      if (!duplicateBusiness.empty) {
        const noConflictingBusiness = selectedBusiness?.id
          ? duplicateBusiness.docs.every(
              (doc) => doc.id === selectedBusiness.id
            )
          : false;
        if (!noConflictingBusiness)
          throw new Error("A business with these phone numbers already exists");
      }

      if (selectedBusiness?.id) {
        // perform update operation
        const businessOwnerRef = doc(db, "businessOwners", selectedBusiness.id);
        await updateDoc(businessOwnerRef, values);
        messageApi.success("Document successfully updated!");
      } else {
        // Check for duplicate phone numbers

        const businessOwnerRef = doc(collection(db, "businessOwners"));
        await setDoc(businessOwnerRef, values);
        messageApi.success("Document successfully written!");
        form.resetFields();
      }
      onClose();
    } catch (error) {
      console.error("Error writing document: ", error);
      messageApi.error("Error writing document: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBusiness) {
      setUrl(`${window.location.hostname}/review/${selectedBusiness.id}`);
      if (selectedBusiness.smsSubExpiry)
        selectedBusiness.smsSubExpiry = dayjs(selectedBusiness?.smsSubExpiry);

      selectedBusiness.lastSendToAllDate = selectedBusiness?.lastSendToAllDate
        ? dayjs(selectedBusiness?.lastSendToAllDate)
        : null;

      selectedBusiness.businessActiveTillDate =
        selectedBusiness?.businessActiveTillDate
          ? dayjs(selectedBusiness?.businessActiveTillDate)
          : null;

      selectedBusiness.phone1 = removeCountryCodeFromStart(
        selectedBusiness.countryCode,
        selectedBusiness.phone1?.toString()
      );

      if (selectedBusiness.phone2) {
        selectedBusiness.phone2 = removeCountryCodeFromStart(
          selectedBusiness.countryCode,
          selectedBusiness.phone2?.toString()
        );
      }

      form.setFieldsValue(selectedBusiness);
    } else {
      form.resetFields();
      form.setFieldsValue({
        phone2: null,
        countryCode: "+1",
        lastSendToAllDate: null,
        businessActiveTillDate: null,
        defaultReviewEntryUrl: reviewEntryOptions[0]?.value,
        customerVisits: 0,
        collectCustomerInfo: true,
        reviewEntryUrl: null,
        reviewEntryUrl2: null,
        reviewEntryUrl3: null,
        reviewEntryUrl4: null,
        notes: "",
        smsSubExpiry: null,
        smsCouponSubService: false,
      });
    }
  }, [selectedBusiness, form]);

  return {
    form,
    handleManageBusiness,
    messageContext,
    messageApi,
    loading,
    smsCouponSubService,
    reviewEntryUrl,
    reviewEntryUrl2,
    reviewEntryUrl3,
    reviewEntryUrl4,
    isAdmin: user.role === ROLES.ADMIN,
    url,
    spaceStyle,
  };
};

export default useManageBusinessDrawer;
