import { useState } from "react";

const useDefaultLayout = () => {
  const [isSiderOpen, setIsSiderOpen] = useState(true);

  const handleToggleSider = () => setIsSiderOpen(!isSiderOpen);

  return { handleToggleSider, isSiderOpen };
};

export default useDefaultLayout;
