import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { getRoutes } from "./";
import useUser from "global/context/user/useUser";
import { Loader } from "global/components";

const Navigation = () => {
  const { isAuthenticated, user, isUnauthorizedBusiness } = useUser();

  if (isAuthenticated === null) {
    return <Loader fullPage />;
  }

  return (
    <RouterProvider
      router={createBrowserRouter(
        getRoutes(isAuthenticated, user?.role, isUnauthorizedBusiness)
      )}
    />
  );
};

export default Navigation;
