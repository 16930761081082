export const getOptions = (chartData) => ({
  chart: {
    type: "bar",
    foreColor: "#FFFFFF", // Text color
    background: "transparent",
  },
  xaxis: {
    categories: chartData?.map((item) => item.name),
  },
  yaxis: {
    labels: {
      style: {
        colors: "#FFFFFF", // Y-axis labels color
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5, // Border radius for the top sides of the bars
      distributed: true,
      horizontal: false,
      columnWidth: "25%",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '20px', // Font size of the count inside the bars
      fontWeight: 'bold', // Font weight of the count inside the bars
      colors: ['#FFFFFF'], // Text color of the count inside the bars
    },
  },
  grid: {
    show: false, // This will remove the grid lines behind the bars
  },
  theme: {
    mode: "dark", // This will set the dark mode for the chart
  },
});
