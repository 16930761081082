export const reviewEntryOptions = [
  {
    label: "Review Entry url",
    value: "reviewEntryUrl",
  },
  {
    label: "Review Entry url 2",
    value: "reviewEntryUrl2",
  },
  {
    label: "Review Entry url 3",
    value: "reviewEntryUrl3",
  },
  {
    label: "Review Entry url 4",
    value: "reviewEntryUrl4",
  },
];
