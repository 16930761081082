import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { collection, deleteDoc, doc, onSnapshot } from "@firebase/firestore";
import { Button, Space } from "antd";
import useMessage from "antd/es/message/useMessage";
import useNotification from "antd/es/notification/useNotification";
import dayjs from "dayjs";
import { db } from "firebaseConfig/config";
import { ROLES } from "global/constants";
import { useUser } from "global/context";
import { useDevEffect } from "global/hooks";
import Papa from "papaparse";
import { useCallback, useEffect, useMemo, useState } from "react";

const useCustomers = () => {
  const [isManageCustomerDrawerVisible, setIsManageCustomerDrawerVisible] =
    useState(false);
  const { user } = useUser();
  const [selectedBusinessId, setSelectedBusinessId] = useState(
    user?.role === ROLES.BUSINESS_OWNER ? user.id : null
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [messageApi, messageContext] = useMessage();
  const [notificationApi, notificationContext] = useNotification();
  const [businesses, setBusinesses] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [isCouponSubExpired, setIsCouponSubExpired] = useState(false);
  const [isCouponModalVisible, setIsCouponModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBusinesses = useCallback(() => {
    if (user?.role === ROLES.BUSINESS_OWNER) {
      setBusinesses([user]);
      return;
    }
    const q = collection(db, "businessOwners");
    setLoading(true);

    onSnapshot(q, (snapshot) => {
      let businesses = [];
      snapshot.forEach(
        (doc) => {
          businesses.push({
            id: doc.id,
            ...doc.data(),
          });
        },
        (error) => {
          // Handling error here
          console.error("Error fetching businesses: ", error);
          messageApi.error(
            "An error occurred while fetching businesses. Please try again later."
          );
        }
      );

      setBusinesses(businesses);
      setLoading(false);
    });
  }, [messageApi, user]);

  const getCustomersOfBusinessOwner = useCallback(
    (businessOwnerId) => {
      let unsubscribe = () => {};
      setLoading(true);

      try {
        const businessOwnerRef = doc(db, "businessOwners", businessOwnerId);
        const customersCollectionRef = collection(
          businessOwnerRef,
          "customers"
        );

        unsubscribe = onSnapshot(
          customersCollectionRef,
          (snapshot) => {
            const customers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setCustomers(customers);
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching customers: ", error);
            messageApi.error("Error fetching customers: " + error.message);
            setLoading(false);
          }
        );
      } catch (error) {
        console.error("Error attaching listener: ", error);
        messageApi.error("Error attaching listener: " + error.message);
        setLoading(false);
      }

      return unsubscribe;
    },
    [messageApi]
  );

  const handleBusinessSelect = (selectedBusinessId) => {
    setSelectedBusinessId(selectedBusinessId);
  };

  const toggleManageCustomerDrawer = () => {
    if (isManageCustomerDrawerVisible) setSelectedCustomer(null);
    setIsManageCustomerDrawerVisible(!isManageCustomerDrawerVisible);
  };

  const handleEditCustomersRequest = (record) => (e) => {
    e?.stopPropagation();
    setSelectedCustomer({ ...record });
    toggleManageCustomerDrawer();
  };

  const deleteCustomer = (id) => async (e) => {
    try {
      e?.stopPropagation();
      const businessOwnerRef = doc(db, "businessOwners", selectedBusinessId);
      const customerRef = doc(businessOwnerRef, "customers", id);
      await deleteDoc(customerRef);
      messageApi.success("Customer successfully deleted!");
    } catch (error) {
      console.error("Error deleting customer: ", error);
      messageApi.error("Error deleting customer: " + error.message);
    }
  };

  const downloadCSV = () => {
    if (!customers) return;
    const csv = Papa.unparse(customers);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customers.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleCouponModal = () => {
    if (!selectedBusiness) {
      messageApi.error("Please select a business first");
      return;
    }

    if (!isCouponModalVisible && !selectedBusiness?.smsCouponSubService) {
      messageApi.error("Coupon is not enabled for this business");
      return;
    }
    setIsCouponModalVisible(!isCouponModalVisible);
  };

  const uniquePhoneNumbers = useMemo(
    () => (customers ? [...new Set(customers.map((item) => item.phone))] : []),
    [customers]
  );

  const phoneNumberFilters = useMemo(
    () =>
      uniquePhoneNumbers.map((phone) => ({
        text: phone,
        value: phone,
      })),
    [uniquePhoneNumbers]
  );

  const selectedBusiness = useMemo(
    () => businesses?.find(({ id }) => selectedBusinessId === id),
    [selectedBusinessId, businesses]
  );

  const customerTableColumns = [
    {
      title: "Customer Name",
      dataIndex: "name",
    },
    {
      title: "Customer Phone",
      dataIndex: "phone",
      filterSearch: true,
      onFilter: (value, record) => `${record?.phone}`.includes(value),
      filters: phoneNumberFilters,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={handleEditCustomersRequest(record)}
          />

          <Button
            size="small"
            danger
            icon={<DeleteOutlined />}
            onClick={deleteCustomer(record.id)}
          />
        </Space>
      ),
    },
  ];

  const onRowClick = (record) => {
    return {
      onClick: handleEditCustomersRequest(record), // Open edit drawer on row click
    };
  };

  useDevEffect(() => {
    if (user.role === ROLES.BUSINESS_OWNER) {
      const currentDate = dayjs(); // Current date with dayjs
      const expiryDate = dayjs(user.smsSubExpiry); // Expiry date from the user object with dayjs

      if (currentDate.isAfter(expiryDate)) {
        // Subscription has expired
        // Handle any other logic or display a message if needed

        notificationApi.warning({
          message: "Warning",
          description: "Your subscription has expired. Please renew.",
          duration: 0,
          placement: "bottomLeft",
        });

        setIsCouponSubExpired(true);
      }
    }
    return () => notificationApi.destroy();
  }, [user]);

  useDevEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  useEffect(() => {
    if (!selectedBusinessId) return;
    getCustomersOfBusinessOwner(selectedBusinessId);
  }, [selectedBusinessId, getCustomersOfBusinessOwner]);

  return {
    messageContext,
    getCustomersOfBusinessOwner,
    businesses,
    customers,
    handleBusinessSelect,
    loading,
    isManageCustomerDrawerVisible,
    toggleManageCustomerDrawer,
    selectedBusinessId,
    customerTableColumns,
    selectedCustomer,
    downloadCSV,
    userRole: user?.role,
    toggleCouponModal,
    isCouponModalVisible,
    selectedBusiness,
    onRowClick,
    notificationContext,
    isCouponSubExpired,
  };
};

export default useCustomers;
