import { Form, Select, message } from "antd";

export const triggerCopyMessage = () => message.success("Copied to Clipboard");

export const countryCodes = [
  { code: "+1", name: "United States", flag: "🇺🇸" },
  { code: "+44", name: "United Kingdom", flag: "🇬🇧" },
  { code: "+33", name: "France", flag: "🇫🇷" },
  { code: "+49", name: "Germany", flag: "🇩🇪" },
  { code: "+91", name: "India", flag: "🇮🇳" },
  { code: "+81", name: "Japan", flag: "🇯🇵" },
  { code: "+61", name: "Australia", flag: "🇦🇺" },
  { code: "+55", name: "Brazil", flag: "🇧🇷" },
  { code: "+86", name: "China", flag: "🇨🇳" },
  { code: "+7", name: "Russia", flag: "🇷🇺" },
  { code: "+39", name: "Italy", flag: "🇮🇹" },
  { code: "+34", name: "Spain", flag: "🇪🇸" },
  { code: "+31", name: "Netherlands", flag: "🇳🇱" },
  { code: "+27", name: "South Africa", flag: "🇿🇦" },
  { code: "+64", name: "New Zealand", flag: "🇳🇿" },
  { code: "+82", name: "South Korea", flag: "🇰🇷" },
  { code: "+90", name: "Turkey", flag: "🇹🇷" },
  { code: "+47", name: "Norway", flag: "🇳🇴" },
  { code: "+46", name: "Sweden", flag: "🇸🇪" },
  { code: "+60", name: "Malaysia", flag: "🇲🇾" },
  { code: "+62", name: "Indonesia", flag: "🇮🇩" },
  { code: "+63", name: "Philippines", flag: "🇵🇭" },
  { code: "+65", name: "Singapore", flag: "🇸🇬" },
  { code: "+66", name: "Thailand", flag: "🇹🇭" },
  { code: "+98", name: "Iran", flag: "🇮🇷" },
  { code: "+972", name: "Israel", flag: "🇮🇱" },
  { code: "+20", name: "Egypt", flag: "🇪🇬" },
  { code: "+30", name: "Greece", flag: "🇬🇷" },
  { code: "+52", name: "Mexico", flag: "🇲🇽" },
  { code: "+51", name: "Peru", flag: "🇵🇪" },
  {
    code: "+92",
    name: "Pakistan",
    flag: "🇵🇰",
  },
];

export const countryCodePrefixSelector = (
  <Form.Item name="countryCode" noStyle>
    <Select
      style={{ minWidth: 90 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.value ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {countryCodes.map((countryCode) => (
        <Select.Option value={countryCode.code} key={countryCode.code}>
          {countryCode.flag} {countryCode.code}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

export const removeCountryCodeFromStart = (
  countryCode = "",
  phoneNumber = ""
) => {
  // Remove any non-numeric characters from both countryCode and phoneNumber for accurate comparison
  const sanitizedCountryCode = countryCode.replace(/[^\d]/g, "");
  const sanitizedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");

  if (sanitizedPhoneNumber.startsWith(sanitizedCountryCode)) {
    return sanitizedPhoneNumber.substring(sanitizedCountryCode.length);
  }

  // If the phoneNumber doesn't start with the countryCode, return the phoneNumber as-is
  return phoneNumber;
};


export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
