export const DRAWER_MODES = {
  ADD: "ADD",
  EDIT: "EDIT",
};

export const ROLES = {
  ADMIN: "admin",
  BUSINESS_OWNER: "business_owner",
};

export const MODULES = {
  DASHBOARD: "dashboard",
  BUSINESSES: "businesses",
  CUSTOMERS: "customers",
  COUPONS: "coupons",
};

export const ACTIONS = {
  VIEW: "view",
  UPDATE: "update",
  DELETE: "delete",
};

export const PERMISSIONS = {
  [ROLES.ADMIN]: {
    [MODULES.DASHBOARD]: [ACTIONS.VIEW, ACTIONS.UPDATE, ACTIONS.DELETE],
    [MODULES.BUSINESSES]: [ACTIONS.VIEW, ACTIONS.UPDATE, ACTIONS.DELETE],
    [MODULES.CUSTOMERS]: [ACTIONS.VIEW, ACTIONS.UPDATE, ACTIONS.DELETE],
    [MODULES.COUPONS]: [],
  },
  [ROLES.BUSINESS_OWNER]: {
    [MODULES.CUSTOMERS]: [ACTIONS.VIEW, ACTIONS.UPDATE],
    [MODULES.COUPONS]: [ACTIONS.VIEW],
  },
};
