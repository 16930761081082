import { Button } from "antd";

const useGlobalVariablesCard = ({ isAdmin, updatingGlobalVariables, form }) => {
  const spaceStyle = { width: "100%", margin: "10px 0 20px" };
  const extra = isAdmin
    ? [
        <Button
          loading={updatingGlobalVariables}
          type="primary"
          block
          onClick={() => form.submit()}
          key="1"
        >
          Update
        </Button>,
      ]
    : [];

  return { spaceStyle, extra };
};

export default useGlobalVariablesCard;
