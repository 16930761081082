import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "firebaseConfig/config";
import { collection, getDocs, or, query, where } from "@firebase/firestore";

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

export const getUserFromPhoneNumber = async (phoneNumber) => {
  const businessOwnersCollection = collection(db, "businessOwners");
  const q = query(
    businessOwnersCollection,
    or(where("phone1", "==", +phoneNumber), where("phone2", "==", +phoneNumber))
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    // If we found a business owner, return the first one
    const doc = querySnapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    };
  }
  // If no business owner was found, return null
  return null;
};
