import { useLayout } from "global/context";
import { useEffect } from "react";

const WithLayout = ({ component, heading }) => {
  const { setActiveHeading } = useLayout();

  useEffect(() => {
    setTimeout(() => {
      setActiveHeading(heading);
    }, 0);
    return () => {
      setActiveHeading("");
    };
  }, [heading, setActiveHeading]);

  return component;
};

export default WithLayout;
