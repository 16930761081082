import { Outlet } from "react-router-dom";
import "./pageLayout.styles.scss";
import { Layout } from "antd";

const PageLayout = () => {
  return (
    <Layout className="page-layout-wrapper">
      <Outlet />
    </Layout>
  );
};

export default PageLayout;
