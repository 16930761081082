import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import "./authLayout.styles.scss";

const AuthLayout = () => {
  return (
    <Layout className="auth-layout-wrapper">
      <Outlet />
    </Layout>
  );
};

export default AuthLayout;
