import { LayoutProvider, UserProvider } from "global/context";
import { WithAntdTheme } from "global/hoc";
import { Navigation } from "navigation";

function App() {
  return (
    <UserProvider>
      <LayoutProvider>
        <WithAntdTheme>
          <Navigation />
        </WithAntdTheme>
      </LayoutProvider>
    </UserProvider>
  );
}

export default App;
