import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import { triggerCopyMessage } from "global/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import useGlobalVariablesCard from "./useGlobalVariablesCard.hook";

const GlobalVariablesCard = ({
  isAdmin,
  onFinish,
  form,
  paypalLink,
  updatingGlobalVariables,
}) => {
  const { extra, spaceStyle } = useGlobalVariablesCard({
    updatingGlobalVariables,
    form,
    isAdmin,
  });

  return (
    <Card title={"GLOBAL VARIABLES"} extra={extra}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <Col xs={24}>
            <label htmlFor="paypalLink">Paypal Link</label>
            <Space.Compact style={spaceStyle}>
              <Form.Item name={"paypalLink"} noStyle>
                <Input prefix="https://" disabled={!isAdmin} />
              </Form.Item>
              <CopyToClipboard text={paypalLink} onCopy={triggerCopyMessage}>
                <Button type="primary" disabled={!paypalLink}>
                  Copy
                </Button>
              </CopyToClipboard>
            </Space.Compact>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GlobalVariablesCard;
