import { Outlet } from "react-router-dom";
import "./defaultLayout.styles.scss";
import { Header, Sider } from "./components";
import useDefaultLayout from "./defaultLayout.hook";

const DefaultLayout = () => {
  const { handleToggleSider, isSiderOpen } = useDefaultLayout();

  return (
    <main className="default-layout-wrapper">
      <Sider open={isSiderOpen} onToggleSider={handleToggleSider} />
      <section className="default-layout-right">
        <Header onToggleSider={handleToggleSider} />
        <div className="default-layout-page-wrapper">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default DefaultLayout;
