import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Typography } from "antd";
import { Loader } from "global/components";
import { countryCodePrefixSelector } from "global/utils";
import { formRules } from "./review.formRules";
import "./review.styles.scss";
import useReview from "./useReview.hook";

const Review = () => {
  const {
    isBusinessIdInvalid,
    messageContext,
    handleFormSubmit,
    form,
    submittingForm,
    notificationContext,
    fetchingData,
  } = useReview();

  return (
    <>
      {notificationContext}
      {messageContext}
      <div className="review-page-wrapper">
        {fetchingData ? (
          <Loader />
        ) : (
          <Form
            disabled={isBusinessIdInvalid}
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={{ email: null }}
            form={form}
            className="review-form-wrapper"
          >
            <>
              <div className="review-form-header">
                <Typography.Title level={3} className="font-bold">
                  PLEASE ENTER YOUR DETAILS
                </Typography.Title>
              </div>
              <Divider className="m-0" />
              <div className="review-form-fields">
                <Form.Item
                  label="Welcome to"
                  name={"businessName"}
                  rules={formRules.name}
                >
                  <Input
                    placeholder="Please enter your business name"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Please enter your name"
                  name={"name"}
                  rules={formRules.name}
                >
                  <Input placeholder="Please enter your name" />
                </Form.Item>
                <Form.Item
                  label="Please enter your phone number"
                  name={"phone"}
                  rules={formRules.phone}
                >
                  <Input
                    placeholder="Please enter your phone no"
                    addonBefore={countryCodePrefixSelector}
                  />
                </Form.Item>
                <Form.Item
                  label="Please enter your email"
                  name={"email"}
                  rules={formRules.email}
                >
                  <Input placeholder="Please enter your email" />
                </Form.Item>
              </div>
              <div className="review-form-footer">
                <Button type="link" className="m-0 p-0">
                  Terms and Conditions Apply
                </Button>

                <Button
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  disabled={isBusinessIdInvalid}
                  loading={submittingForm}
                  htmlType="submit"
                >
                  Proceed
                </Button>
              </div>
            </>
          </Form>
        )}
      </div>
    </>
  );
};

export default Review;
