import {
  AppstoreOutlined,
  FundOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Typography, Grid, Drawer } from "antd";
import { MODULES, ACTIONS } from "global/constants";
import { useUser } from "global/context";
import { WithPermission } from "global/hoc";
import { NavLink } from "react-router-dom";

const { useBreakpoint } = Grid;

const Sider = ({ open, onToggleSider }) => {
  const { user } = useUser();
  const screens = useBreakpoint();

  const siderJsx = (
    <aside className={`default-layout-sider ${open ? "open" : "closed"}`}>
      {screens.md && (
        <div className="app-logo">
          <Typography.Title level={3} className="m-0">
            5 Star Review
          </Typography.Title>
        </div>
      )}
      <div className="navlinks-wrapper">
        <WithPermission
          action={ACTIONS.VIEW}
          module={MODULES.DASHBOARD}
          role={user?.role}
        >
          <MenuLink to="" text="Dashboard" Icon={AppstoreOutlined} />
        </WithPermission>
        <WithPermission
          action={ACTIONS.VIEW}
          module={MODULES.BUSINESSES}
          role={user?.role}
        >
          <MenuLink to="businesses" text="Businesses" Icon={FundOutlined} />
        </WithPermission>
        <WithPermission
          action={ACTIONS.VIEW}
          module={MODULES.CUSTOMERS}
          role={user?.role}
        >
          <MenuLink
            to="customers"
            text="Customers"
            Icon={UsergroupAddOutlined}
          />
        </WithPermission>
      </div>
    </aside>
  );

  const mobileSider = (
    <Drawer
      placement="left"
      title="Menu"
      className="ant-sider"
      width={200}
      open={open}
      onClose={onToggleSider}
    >
      {siderJsx}
    </Drawer>
  );

  return screens.md ? siderJsx : mobileSider;
};

const MenuLink = ({ to = "", Icon, text = "" }) => (
  <NavLink
    to={to}
    end
    className={({ isActive }) => `navlink ${isActive ? "active" : ""}`}
  >
    <Icon className="nav-icon" />
    <Typography.Text className="navlink-text">{text}</Typography.Text>
  </NavLink>
);

export default Sider;
