export const formRules = {
  name: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  address: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  phone1: [
    {
      required: true,
      message: "This field is reqiured",
    },
    {
      pattern: /^\d{1,4}[- ]?\d{7,10}$/,
      message: "Please enter a valid phone number",
    },
  ],
  phone2: [
    {
      required: false,
      message: "This field is reqiured",
    },
    {
      pattern: /^\d{1,4}[- ]?\d{7,10}$/,
      message: "Please enter a valid phone number",
    },
  ],
  contactPerson: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  email: [
    {
      required: true,
      message: "This field is reqiured",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  businessActiveTillDate: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],

  smsOptIn: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  smsCouponSubService: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  collectCustomerInfo: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  defaultReviewEntryUrl: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
  reviewEntryUrl: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          getFieldValue("defaultReviewEntryUrl") === "reviewEntryUrl" &&
          !value
        ) {
          return Promise.reject(new Error("This field is required"));
        }
        return Promise.resolve();
      },
    }),
  ],
  reviewEntryUrl2: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          getFieldValue("defaultReviewEntryUrl") === "reviewEntryUrl2" &&
          !value
        ) {
          return Promise.reject(new Error("This field is required"));
        }
        return Promise.resolve();
      },
    }),
  ],
  reviewEntryUrl3: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          getFieldValue("defaultReviewEntryUrl") === "reviewEntryUrl3" &&
          !value
        ) {
          return Promise.reject(new Error("This field is required"));
        }
        return Promise.resolve();
      },
    }),
  ],
  reviewEntryUrl4: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          getFieldValue("defaultReviewEntryUrl") === "reviewEntryUrl4" &&
          !value
        ) {
          return Promise.reject(new Error("This field is required"));
        }
        return Promise.resolve();
      },
    }),
  ],

  smsSubExpiry: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value && getFieldValue("smsCouponSubService") === true) {
          console.log(getFieldValue("smsCouponSubService"), value);
          return Promise.reject(new Error("This field is required"));
        }
        return Promise.resolve();
      },
    }),
  ],
  couponMessage: [
    {
      required: true,
      message: "This field is reqiured",
    },
  ],
};
