import { doc, updateDoc } from "@firebase/firestore";
import { useForm, useWatch } from "antd/es/form/Form";
import useMessage from "antd/es/message/useMessage";
import axios from "axios";
import dayjs from "dayjs";
import { db } from "firebaseConfig/config";
import { useEffect, useState } from "react";

const useCouponModal = ({ customers, businessInfo, handleClose }) => {
  const [form] = useForm();
  const selectedCustomers = useWatch("selectedCustomers", form);
  const selectAll = useWatch("selectAll", form);
  const [messageApi, messageContext] = useMessage();
  const [loading, setLoading] = useState(false);
  const [customersMap, setCustomersMap] = useState(new Map());
  const [isSendToAllCooldownActive, setIsSendToAllCooldownActive] =
    useState(false);
  const COOLDOWN_PERIOD = 14; // 2 weeks in days

  const handleSendSms = async (values) => {
    setLoading(true);
    const numbers = selectedCustomers.map(
      (selectedCustomer) => `+${customersMap.get(selectedCustomer)?.phone}`
    );
    const message =
      values?.couponMessage +
      `Note:  Coupon expires on ${dayjs(businessInfo?.smsSubExpiry).format(
        "YYYY-MM-DD"
      )}`;
    try {
      await updateCouponMessageInDb(values?.couponMessage);

      const result = await axios.post(
        "https://bmp-backend.vercel.app/api/sendSms",
        {
          numbers,
          message,
        }
      );

      if (selectAll) {
        await updateLastSendToAllDate();
        setIsSendToAllCooldownActive(true);
        form.setFieldValue("selectAll", false);
      }
      messageApi[result?.data?.status](result?.data?.data);

      handleClose();
    } catch (err) {
      console.error(err);
      messageApi.error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const updateCouponMessageInDb = async (newMessage) => {
    const businessOwnerDoc = doc(db, "businessOwners", businessInfo.id);
    await updateDoc(businessOwnerDoc, { couponMessage: newMessage });
  };

  const updateLastSendToAllDate = async () => {
    const businessOwnerDoc = doc(db, "businessOwners", businessInfo.id);
    const currentTimestamp = new Date().toISOString(); // UTC Timestamp
    await updateDoc(businessOwnerDoc, { lastSendToAllDate: currentTimestamp });
  };

  useEffect(() => {
    const lastSendDate = dayjs(businessInfo?.lastSendToAllDate);
    const currentDate = dayjs();
    const daysSinceLastSend = currentDate.diff(lastSendDate, "day");
    setIsSendToAllCooldownActive(daysSinceLastSend < COOLDOWN_PERIOD);
    form.setFieldValue("selectAll", false);
  }, [businessInfo, form]);

  useEffect(() => {
    selectAll
      ? form.setFieldValue(
          "selectedCustomers",
          customers.map(({ id }) => id)
        )
      : form.resetFields(["selectedCustomers"]);
  }, [selectAll, form, customers]);

  useEffect(() => {
    if (!customers?.length) return;
    const customerMap = new Map();
    customers.forEach((customer) => {
      customerMap.set(customer.id, customer);
    });
    setCustomersMap(customerMap);
  }, [customers]);

  useEffect(() => {
    form.setFieldValue("couponMessage", businessInfo?.couponMessage);
  }, [businessInfo, form]);

  return {
    selectedCustomers,
    handleSendSms,
    messageContext,
    form,
    loading,
    isSendToAllCooldownActive,
  };
};

export default useCouponModal;
