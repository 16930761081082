import { useForm } from "antd/es/form/Form";
import useMessage from "antd/es/message/useMessage";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc
} from "firebase/firestore";
import { db } from "firebaseConfig/config";
import { ROLES } from "global/constants";
import { useUser } from "global/context";
import { removeCountryCodeFromStart } from "global/utils";
import { useCallback, useEffect, useState } from "react";

const useManageCustomerDrawer = ({ selectedCustomer, onClose, businesses }) => {
  const [messageApi, messageContext] = useMessage();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [form] = useForm();

  const handleManageCustomer = async (values) => {
    try {
      setLoading(true);

      values.couponUsedDate = values.couponUsedDate
        ? values.couponUsedDate.toISOString()
        : null;

      values.phone = +`${values.countryCode}${values.phone}`.substring(1);

      const businessOwnerRef = doc(db, "businessOwners", values.businessId);
      const customersCollectionRef = collection(businessOwnerRef, "customers");

      if (selectedCustomer?.id) {
        // If the phone number has been changed
        if (selectedCustomer.phone !== values.phone) {
          // Delete the old document
          const oldCustomerDocRef = doc(
            customersCollectionRef,
            selectedCustomer.phone.toString()
          );
          await deleteDoc(oldCustomerDocRef);

          // Create a new document with the new phone number as the ID
          const newCustomerDocRef = doc(
            customersCollectionRef,
            values.phone.toString()
          );
          await setDoc(newCustomerDocRef, values);
        } else {
          // If the phone number hasn't changed, update the existing document
          const customerDocRef = doc(
            customersCollectionRef,
            values.phone.toString()
          );
          await setDoc(customerDocRef, values, { merge: true });
        }
        messageApi.success("Customer updated successfully!");
      } else {
        const customerDocRef = doc(
          customersCollectionRef,
          values.phone.toString()
        );

        // Check if a document with the same phone number already exists
        const docSnapshot = await getDoc(customerDocRef);
        if (docSnapshot.exists()) {
          messageApi.error("A customer with this phone number already exists!");
          return;
        }

        await setDoc(customerDocRef, values);
        messageApi.success("Customer added successfully!");
      }

      resetFormFields(businesses, user?.role, form);
      onClose();
    } catch (error) {
      console.error("Error writing document: ", error);
      messageApi.error("Error writing document: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetFormFields = useCallback((businesses, userRole, form) => {
    form.resetFields();
    form.setFieldsValue({
      email: "",
      notes: "",
      countryCode: "+1",
      ...(ROLES.BUSINESS_OWNER === userRole && {
        businessId: businesses?.[0]?.id,
      }),
    });
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      if (selectedCustomer.couponUsedDate)
        selectedCustomer.couponUsedDate = dayjs(
          selectedCustomer.couponUsedDate
        );
      selectedCustomer.phone = removeCountryCodeFromStart(
        selectedCustomer.countryCode.toString(),
        selectedCustomer.phone.toString()
      );
      form.setFieldsValue(selectedCustomer);
    } else {
      resetFormFields(businesses, user?.role, form);
    }
  }, [selectedCustomer, form, businesses, user?.role, resetFormFields]);

  return {
    form,
    handleManageCustomer,
    messageContext,
    loading,
    userRole: user?.role,
  };
};

export default useManageCustomerDrawer;
