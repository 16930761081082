import { Card } from "antd";
import { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { getOptions } from "./barChart.config";

const BarChart = ({ chartData, loading }) => {
  const series = [
    {
      name: "Customers",
      data: chartData?.map((item) => item.customersCount),
    },
  ];

  const options = useMemo(() => getOptions(chartData), [chartData]);

  const chartJsx = chartData ? (
    <ApexCharts options={options} series={series} type="bar" />
  ) : (
    <></>
  );

  return (
    <Card title={"BUSINESS TREND"} loading={loading}>
      {chartJsx}
    </Card>
  );
};

export default BarChart;
