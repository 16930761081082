import { LoginOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Typography } from "antd";
import { ROLES } from "global/constants";
import { formRules } from "./login.formRules";
import "./login.styles.scss";
import useLogin from "./useLogin.hook";

const Login = () => {
  const {
    handleLoginFormSubmit,
    messageContext,
    loading,
    handleBusinessOwnerLogin,
    loginType,
  } = useLogin();

  return (
    <>
      {messageContext}
      <div
        id="firebaseui-auth-container"
        className={loginType === ROLES.ADMIN ? "hidden" : ""}
      />

      {loginType === ROLES.ADMIN && (
        <div className="login-page-wrapper">
          <div className="login-form-wrapper">
            <Form layout="vertical" onFinish={handleLoginFormSubmit}>
              <div className="login-form-header">
                <Typography.Title level={3} className="font-bold">
                  ADMIN LOGIN
                </Typography.Title>
              </div>
              <Divider className="m-0" />
              <div className="login-form-fields">
                <Form.Item label="Email" name={"email"} rules={formRules.email}>
                  <Input placeholder="Please enter your email" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name={"password"}
                  rules={formRules.password}
                >
                  <Input.Password placeholder="Please enter your password" />
                </Form.Item>
              </div>
              <div className="login-form-footer">
                <Button type="text" onClick={handleBusinessOwnerLogin}>
                  Business Owner Login?
                </Button>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  icon={<LoginOutlined />}
                >
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
