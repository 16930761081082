import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { Button, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import useMessage from "antd/es/message/useMessage";
import useNotification from "antd/es/notification/useNotification";
import { db } from "firebaseConfig/config";
import { useDevEffect } from "global/hooks";
import { isSafari } from "global/utils";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useReview = () => {
  const { businessId } = useParams();
  const [messageApi, messageContext] = useMessage();
  const [business, setBusiness] = useState(null);
  const [isBusinessIdInvalid, setIsBusinessIdInvalid] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const navigate = useNavigate();
  const [form] = useForm();
  const [notificationApi, notificationContext] = useNotification();

  const fetchPaypalLink = useCallback(async () => {
    const globalVariablesSnap = await getDocs(
      collection(db, "globalVariables")
    );
    if (!globalVariablesSnap.empty) {
      const paypalLink = globalVariablesSnap.docs[0].data().paypalLink;
      return paypalLink; // Return the PayPal link
    }
    return null; // Return null if not found
  }, []);

  const openReviewNotification = (reviewLink) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="link"
          size="small"
          onClick={() => notificationApi.destroy()}
        >
          No thanks
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notificationApi.destroy(key);
            window.open(reviewLink, "_blank");
          }}
        >
          Write a Review
        </Button>
      </Space>
    );

    notificationApi.open({
      message: "Review Request",
      description: "Please click the button below to write a review.",
      btn,
      key,
      duration: 0,
      closeIcon: false,
    });
  };

  const handleFormSubmit = async (values) => {
    try {
      setSubmittingForm(true);
      values.phone = +`${values.countryCode}${values.phone}`.substring(1);
      // Use the phone number as the document ID
      const reviewDocRef = doc(
        db,
        "businessOwners",
        businessId,
        "customers",
        values.phone.toString()
      );

      // Check if a review with the same phone number already exists
      const docSnapshot = await getDoc(reviewDocRef);
      if (docSnapshot.exists()) {
        form.resetFields(["email", "phone", "name"]);
        navigate("/review/thanks");
        return;
      }

      // Add the new review to the reviews sub-collection using phone number as the ID
      await setDoc(reviewDocRef, values);
      messageApi.success(
        "Customer added successfully! Redirecting to review link"
      );

      form.resetFields(["email", "phone", "name"]);
      // Redirect to Google review URL from business doc
      const reviewLink = `https://${
        business[business?.defaultReviewEntryUrl || "reviewEntryUrl"]
      }`;

      if (isSafari()) {
        openReviewNotification(reviewLink);
      } else {
        setTimeout(() => {
          window.open(reviewLink, "_blank");
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding review ", error);
      messageApi.error("Error adding review: " + error.message);
    } finally {
      setSubmittingForm(false);
    }
  };

  useDevEffect(() => {
    const onInit = async () => {
      try {
        setFetchingData(true);
        const paypalLink = await fetchPaypalLink(); // Fetch PayPal link
        const businessRef = doc(db, "businessOwners", businessId);
        const businessSnap = await getDoc(businessRef);
        if (businessSnap.exists()) {
          let businessData = businessSnap.data();
          if (
            businessData?.businessActiveTillDate &&
            new Date(businessData.businessActiveTillDate) < new Date()
          ) {
            if (paypalLink) {
              window.location.href = `https://${paypalLink}`; // Redirect to PayPal link
              return;
            }
          }
          setBusiness(businessData);

          // Increment customerVisits count
          const newVisitsCount = (businessData.customerVisits || 0) + 1;
          await updateDoc(businessRef, { customerVisits: newVisitsCount });

          // Redirect if collectCustomerInfo is false
          if (businessData.collectCustomerInfo === false) {
            const redirectUrl = `https://${
              businessData[
                businessData?.defaultReviewEntryUrl || "reviewEntryUrl"
              ]
            }`;
            if (isSafari()) {
              openReviewNotification(redirectUrl);
            } else {
              window.location.href = redirectUrl;
            }
            return;
          }

          form.setFieldsValue({
            businessName: businessData?.name,
            countryCode: "+1",
          });
        } else {
          messageApi.error("Invalid business ID!");
          setIsBusinessIdInvalid(true);
        }
      } catch (err) {
        console.error(err);
        messageApi.error("Failed to load business information.");
      } finally {
        setFetchingData(false);
      }
    };
    onInit();
  }, [businessId, messageApi, form, fetchPaypalLink]);

  return {
    messageContext,
    handleFormSubmit,
    form,
    isBusinessIdInvalid,
    submittingForm,
    notificationContext,
    fetchingData,
  };
};

export default useReview;
