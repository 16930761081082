import { SendOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Row, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import Modal from "antd/es/modal/Modal";
import { MESSAGE_SVG } from "assets";
import "./couponModal.styles.scss";
import useCouponModal from "./useCouponModal.hook";

const CouponModal = ({ open, handleClose, customers, businessInfo }) => {
  const {
    selectedCustomers,
    messageContext,
    form,
    handleSendSms,
    loading,
    isSendToAllCooldownActive,
  } = useCouponModal({
    customers,
    businessInfo,
    handleClose,
  });

  return (
    <>
      {messageContext}
      <Modal
        open={open}
        okText="Send"
        okButtonProps={{ icon: <SendOutlined />, loading }}
        onCancel={handleClose}
        width={700}
        onOk={form.submit}
        destroyOnClose
      >
        <div className="coupon-modal-wrapper">
          <Typography.Title level={2} className="coupon-modal-heading">
            Send Coupon
          </Typography.Title>

          <Row gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <img alt="message" src={MESSAGE_SVG} className="hero-image" />
            </Col>
            <Col xs={24} md={12}>
              <Form form={form} layout="vertical" onFinish={handleSendSms}>
                <Form.Item
                  label="Select Customers"
                  name="selectedCustomers"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    value={selectedCustomers}
                    fieldNames={{ label: "name", value: "id" }}
                    options={customers}
                  />
                </Form.Item>
                <Form.Item name={"couponMessage"} label="Coupon Message">
                  <TextArea />
                </Form.Item>
                <Form.Item noStyle name={"selectAll"} valuePropName="checked">
                  <Checkbox disabled={isSendToAllCooldownActive}>
                    Select All?
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default CouponModal;
