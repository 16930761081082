import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { collection, deleteDoc, doc, onSnapshot } from "@firebase/firestore";
import { Button, Space, Tag } from "antd";
import useMessage from "antd/es/message/useMessage";
import { db } from "firebaseConfig/config";
import Papa from "papaparse";
import { useCallback, useEffect, useMemo, useState } from "react";

const useBusinesses = () => {
  const [businesses, setBusinesses] = useState([]);
  const [isManageBusinessDrawerVisible, setIsManageBusinessDrawerVisible] =
    useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const [messageApi, messageContext] = useMessage();
  const [loading, setLoading] = useState(false);

  const toggleManageBusinessDrawer = () => {
    setIsManageBusinessDrawerVisible(!isManageBusinessDrawerVisible);
  };

  const getBusinesses = useCallback(() => {
    const q = collection(db, "businessOwners");
    setLoading(true);

    onSnapshot(q, (snapshot) => {
      let businesses = [];
      snapshot.forEach(
        (doc) => {
          businesses.push({
            id: doc.id,
            ...doc.data(),
          });
        },
        (error) => {
          // Handling error here
          console.error("Error fetching businesses: ", error);
          messageApi.error(
            "An error occurred while fetching businesses. Please try again later."
          );
        }
      );

      setBusinesses(businesses);
      setLoading(false);
    });
  }, [messageApi]);

  const deleteBusiness = (id) => async (e) => {
    e.stopPropagation();
    setLoading(true);
    messageApi.loading("deleting Record");
    try {
      await deleteDoc(doc(db, "businessOwners", id));
      messageApi.destroy();
      messageApi.success("Business successfully deleted.");
    } catch (error) {
      console.error("Error deleting business: ", error);
      messageApi.error(
        "An error occurred while deleting the business. Please try again later."
      );
      setLoading(false);
    }
  };

  const handleEditBusinessRequest = (record) => (e) => {
    e.stopPropagation();
    setSelectedBusiness({ ...record });
    toggleManageBusinessDrawer();
  };

  const handleAddBusinessRequest = () => {
    setSelectedBusiness(null);
    toggleManageBusinessDrawer();
  };

  const downloadCSV = () => {
    const csv = Papa.unparse(businesses);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "businesses.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Create a list of unique business names and phone numbers for the filters
  const uniqueBusinessNames = useMemo(
    () => [...new Set(businesses.map((business) => business.name))],
    [businesses]
  );
  const uniqueBusinessPhone1s = useMemo(
    () => [...new Set(businesses.map((business) => business.phone1))],
    [businesses]
  );

  const businessTableColumns = [
    {
      title: "Business Name",
      dataIndex: "name",
      filters: uniqueBusinessNames.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.name.includes(value),
      filterSearch: true,
    },
    {
      title: "Business Address",
      dataIndex: "address",
    },

    {
      title: "Business Phone 1",
      dataIndex: "phone1",
      filters: uniqueBusinessPhone1s.map((phone) => ({
        text: phone,
        value: phone,
      })),
      onFilter: (value, record) => `${record.phone1}`.includes(value),
      filterSearch: true,
    },
    {
      title: "Business Phone 2",
      dataIndex: "phone2",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Google Review Link",
      dataIndex: "reviewEntryUrl",
    },

    {
      title: "Coupon Status",
      dataIndex: "smsCouponSubService",
      render: (val) => (
        <Tag color={val ? "success" : "error"}>{val ? "Yes" : "No"}</Tag>
      ),
    },

    {
      title: "Total Customer Visits",
      dataIndex: "customerVisits",
      render: (val) => val || "N/a",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={handleEditBusinessRequest(record)}
          />

          <Button
            size="small"
            danger
            icon={<DeleteOutlined />}
            onClick={deleteBusiness(record.id)}
          />
        </Space>
      ),
    },
  ];

  const onRowClick = (record) => {
    return {
      onClick: handleEditBusinessRequest(record), // Open edit drawer on row click
    };
  };

  useEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  return {
    businesses,
    toggleManageBusinessDrawer,
    isManageBusinessDrawerVisible,
    messageContext,
    businessTableColumns,
    loading,
    selectedBusiness,
    handleAddBusinessRequest,
    downloadCSV,
    onRowClick,
  };
};

export default useBusinesses;
