import { collection, doc, getDocs, onSnapshot } from "@firebase/firestore";
import useMessage from "antd/es/message/useMessage";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "firebaseConfig/config";
import { ROLES } from "global/constants";
import { useDevEffect } from "global/hooks";
import { createContext, useState } from "react";
import { getUserFromPhoneNumber } from "./user.utls";

const UserContext = createContext({ user: null, isAuthenticated: null });

const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [messageApi, messageContext] = useMessage();
  const [isUnauthorizedBusiness, setIsUnauthorizedBusiness] = useState(null);
  const [paypalLink, setPaypalLink] = useState(null);

  useDevEffect(() => {
    const fetchPaypalLink = async () => {
      try {
        const globalVariablesSnap = await getDocs(
          collection(db, "globalVariables")
        );
        if (!globalVariablesSnap.empty) {
          const firstDocData = globalVariablesSnap.docs[0].data();
          if (firstDocData.paypalLink) {
            setPaypalLink(firstDocData.paypalLink);
          }
        }
      } catch (error) {
        console.error("Error fetching PayPal link:", error);
        // Optionally, handle this error with a user notification
      }
    };
    fetchPaypalLink();

    let unsubscribe;
    const authStateSusbcription = onAuthStateChanged(auth, async (authUser) => {
      try {
        if (authUser !== null) {
          const phoneNumber = authUser?.phoneNumber?.substring(1);
          const businessOwner = phoneNumber
            ? await getUserFromPhoneNumber(phoneNumber)
            : null;

          if (businessOwner) {
            setIsUnauthorizedBusiness(false);
            const businessOwnerDocRef = doc(
              db,
              "businessOwners",
              businessOwner.id
            );
            let firstTime = true;
            console.log({ firstTime });
            unsubscribe = onSnapshot(businessOwnerDocRef, (docSnapshot) => {
              if (firstTime) {
                firstTime = false;
                return;
              }
              const updatedBusinessOwnerData = docSnapshot.data();

              setUser((prevUser) => ({
                ...prevUser,
                ...updatedBusinessOwnerData,
              }));
            });

            const businessActiveTill = businessOwner.businessActiveTillDate;
            console.log({ businessActiveTill });
            if (
              businessActiveTill &&
              new Date(businessActiveTill) < new Date()
            ) {
              handleLogoutAndRedirect();
              return;
            }

            setUser({
              ...authUser,
              ...businessOwner,
              role: ROLES.BUSINESS_OWNER,
            });
          } else {
            if (phoneNumber) {
              setIsUnauthorizedBusiness(true);
              messageApi.error("Your business is not registered with us.");
              setIsAuthenticated(true);
              return;
            } else {
              setIsUnauthorizedBusiness(false);
              setUser({
                ...authUser,
                role: ROLES.ADMIN,
              });
            }
          }
        } else {
          setUser(null);
          if (unsubscribe) {
            unsubscribe();
          }
        }
        setIsAuthenticated(authUser !== null);
      } catch (err) {
        console.error(err);
      }
    });

    const handleLogoutAndRedirect = () => {
      auth.signOut();
      if (paypalLink) {
        window.location.href = `https://${paypalLink}`;
      }
    };

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      authStateSusbcription();
    };
  }, [messageApi, paypalLink]);

  return (
    <UserContext.Provider
      value={{ user, isAuthenticated, isUnauthorizedBusiness, setUser }}
    >
      {messageContext}
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
