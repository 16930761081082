import useMessage from "antd/es/message/useMessage";
import { PhoneAuthProvider } from "firebase/auth";
import { authForFirebaseUI } from "firebaseConfig/config";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { ROLES } from "global/constants";
import { login } from "global/context/user/user.utls";
import { useDevEffect } from "global/hooks";
import { useCallback, useState } from "react";

const useLogin = () => {
  const [messageApi, messageContext] = useMessage();
  const [loading, setLoading] = useState(null);
  const [loginType, setLoginType] = useState(ROLES.BUSINESS_OWNER);

  const handleLoginFormSubmit = async ({ email, password }) => {
    try {
      setLoading(true);
      await login(email, password);
      messageApi.success("Successfully logged in");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (errorCode) {
        case "auth/invalid-email":
          messageApi.error("The email address is not valid");
          break;
        case "auth/user-disabled":
          messageApi.error(
            "The account corresponding to this email has been disabled"
          );
          break;
        case "auth/user-not-found":
          messageApi.error("There is no user corresponding to this email");
          break;
        case "auth/wrong-password":
          messageApi.error("The password is invalid for this user");
          break;
        default:
          messageApi.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessOwnerLogin = useCallback(() => {
    setLoginType(ROLES.BUSINESS_OWNER);
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(authForFirebaseUI);

    // FirebaseUI configuration for phone authentication
    const uiConfig = {
      signInSuccessUrl: "/portal/customers", // Redirect URL on successful sign-in
      signInOptions: [
        {
          provider: PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: "PK",
          recaptchaParameters: {
            size: "invisible",
          },
        },
      ],

      callbacks: {
        // Customize the UI on initial render
        uiShown: () => {
          // Overwrite popup styles here
          document.querySelector(
            ".firebaseui-container"
          ).style.backgroundColor = "vars.$dark-bg";
          // Add other styles as needed
          // Create the "Admin Login" button
          const adminLoginButton = document.createElement("button");
          adminLoginButton.innerHTML = "Admin Login";
          adminLoginButton.classList.add("custom-text-btn");

          // Add event listener to the button
          adminLoginButton.addEventListener("click", (event) => {
            event.preventDefault();
            setLoginType(ROLES.ADMIN);
          });

          // Append the button to `.firebaseui-form-actions` class
          const formActions = document.querySelector(
            ".firebaseui-form-actions"
          );
          formActions && formActions.prepend(adminLoginButton);
        },
      },
    };

    // Start the FirebaseUI widget
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  useDevEffect(() => {
    handleBusinessOwnerLogin();
  }, [handleBusinessOwnerLogin]);

  return {
    handleLoginFormSubmit,
    messageContext,
    loading,
    handleBusinessOwnerLogin,
    loginType,
  };
};

export default useLogin;
