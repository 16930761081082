const { useRef, useEffect } = require("react");

export const useDevEffect = (fn, deps = []) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }
    fn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
