import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Grid } from "antd";
import { ROLES } from "global/constants";
import { useLayout } from "global/context";
import { useUser } from "global/context";
import { logout } from "global/context/user/user.utls";
import { useMemo, useState } from "react";
const { useBreakpoint } = Grid;

const useHeader = () => {
  const [isManageBusinessDrawerVisible, setIsManageBusinessDrawerVisible] =
    useState(false);

  const { activeHeading } = useLayout();
  const screens = useBreakpoint();
  const { user } = useUser();

  const handleLogout = async () => {
    await logout();
  };

  const handleToggleBusinessDrawer = () =>
    setIsManageBusinessDrawerVisible(!isManageBusinessDrawerVisible);

  const menu = [
    ...[
      user?.role === ROLES.BUSINESS_OWNER && {
        key: "1",
        label: "Profile",
        icon: <UserOutlined />,
        onClick: handleToggleBusinessDrawer,
      },
    ],
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  const initials = useMemo(() => {
    return user?.role === ROLES.ADMIN
      ? "A"
      : `${user?.name[0]}${user?.name[1]}`.toUpperCase();
  }, [user]);

  const roleName = useMemo(
    () => (user?.role ? user?.role.split("_").join(" ") : ""),
    [user]
  );

  return {
    isManageBusinessDrawerVisible,
    handleToggleBusinessDrawer,
    handleLogout,
    activeHeading,
    menu,
    user,
    initials,
    roleName,
    screens,
  };
};

export default useHeader;
