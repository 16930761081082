import "./loader.styles.scss";

const Loader = ({ fullPage = false }) => {
  return (
    <div className={fullPage ? "full-page loader-wrapper" : "loader-wrapper"}>
      <div className={`loader`}>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
        <div className="loader-square"></div>
      </div>
    </div>
  );
};

export default Loader;
