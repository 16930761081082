export const formRules = {
  name: [
    {
      required: true,
      message: "This field is required",
    },
  ],
  phone: [
    {
      required: true,
      message: "This field is reqiured",
    },
    {
      pattern: /^\d{1,4}[- ]?\d{7,10}$/,
      message: "Please enter a valid phone number",
    },
  ],

  email: [
    {
      type: "email",
      message: "Please enter a valid email",
    },
    {
      required: false,
      message: "This field is required",
    },
  ],
};
