const { Typography } = require("antd");

const CountCard = ({ title, count, icon }) => (
  <div className="count-card">
    <div className="count-card-left">
      <Typography.Title level={5} className="uppercase m-0 font-bold">
        {title}
      </Typography.Title>
      <Typography.Title level={1} className="m-0">
        {count}
      </Typography.Title>
    </div>
    <div className="count-card-right">{icon}</div>
  </div>
);

export default CountCard;
