import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { countryCodePrefixSelector } from "global/utils";
import { formRules } from "./manageCustomerDrawer.formRules";
import useManageCustomerDrawer from "./useManageCustomerDrawer.hook";
import { ROLES } from "global/constants";

const ManageCustomerDrawer = ({
  open,
  onClose,
  businesses,
  selectedCustomer,
}) => {
  const { form, userRole, handleManageCustomer, messageContext, loading } =
    useManageCustomerDrawer({
      onClose,
      selectedCustomer,
      businesses,
    });

  return (
    <>
      {messageContext}
      <Drawer
        open={open}
        onClose={onClose}
        title={"Manage Customer"}
        width={600}
        extra={
          <Button
            loading={loading}
            icon={<CheckOutlined />}
            type="primary"
            onClick={form.submit}
          >
            Save
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleManageCustomer}>
          <Row gutter={[20]}>
            <Col xs={12}>
              <Form.Item
                label="Select Business"
                name="businessId"
                rules={formRules.businessId}
              >
                <Select
                  placeholder="Select Business"
                  options={businesses}
                  fieldNames={{ label: "name", value: "id" }}
                  disabled={
                    selectedCustomer?.id || userRole === ROLES.BUSINESS_OWNER
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label="Customer Name"
                name={"name"}
                rules={formRules.name}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Customer Mobile Phone"
                name={"phone"}
                rules={formRules.phone}
              >
                <InputNumber
                  addonBefore={countryCodePrefixSelector}
                  className="w-100"
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Email" name={"email"} rules={formRules.email}>
                <Input className="w-100" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Notes/Comments" name={"notes"}>
                <TextArea />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Coupon Used Date"
                name={"couponUsedDate"}
                rules={formRules.couponUsedDate}
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Button type="link" className="p-0">
          Terms and Condition Apply
        </Button>
      </Drawer>
    </>
  );
};

export default ManageCustomerDrawer;
