import { FundOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { BarChart, CountCard, GlobalVariablesCard } from "./components";
import "./dashboard.styles.scss";
import useDashbaord from "./useDashboard.hook";

const Dashboard = () => {
  const {
    totalBusinesses,
    totalCustomers,
    chartData,
    globalVariables,
    onGlobalVariablesFormFinish,
    globalVariablesForm,
    paypalLink,
    messageContext,
    isAdmin,
    updatingGlobalVariables,
    loadingBarChart,
  } = useDashbaord();

  return (
    <>
      {messageContext}
      <Row gutter={[20, 10]}>
        <Col xs={24} md={14}>
          <BarChart chartData={chartData} loading={loadingBarChart} />
        </Col>
        <Col xs={24} md={10}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <CountCard
                title={"total customers"}
                count={totalCustomers}
                icon={<UsergroupAddOutlined />}
              />
            </Col>
            <Col span={24}>
              <CountCard
                title={"total businesses"}
                count={totalBusinesses}
                icon={<FundOutlined />}
              />
            </Col>
            <Col span={24}>
              <GlobalVariablesCard
                globalVariables={globalVariables}
                onFinish={onGlobalVariablesFormFinish}
                form={globalVariablesForm}
                paypalLink={paypalLink}
                isAdmin={isAdmin}
                updatingGlobalVariables={updatingGlobalVariables}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
