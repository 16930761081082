import { ConfigProvider, theme } from "antd";

const WithAntdTheme = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          fontFamily: "Poppins",
          colorPrimary: "#319DFF",
        },
        components: {
          Input: {
            colorBgContainer: "#3B4758",
          },
          InputNumber: {
            colorBgContainer: "#3B4758",
          },
          Layout: {
            colorBgLayout: "#1D232C",
          },
          Table: {
            colorBgContainer: "#1D232C",
            colorBorderSecondary: "#3B4758",
          },
          Card: {
            colorBgContainer: "#1D232C",
            colorBorderSecondary: null,
          },
          Drawer: {
            colorBgElevated: "#1D232C",
            colorBorderSecondary: "#3B4758",
            fontSizeLG: 20,
          },
          Select: {
            colorBgContainer: "#3B4758",
          },
          DatePicker: {
            colorBgContainer: "#3B4758",
          },
          Modal: {
            colorBgContainer: "#3B4758",
            colorBgElevated: "#1D232C",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default WithAntdTheme;
