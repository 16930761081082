import { AutoComplete, Form, Input } from "antd";
import useBusinessAntdAutoComplete from "./useBusinessAntdAutoComplete";

const BusinessAntdAutoComplete = ({ form, rules, messageApi }) => {
  const { handleBusinessSelect, renderOptions, setValue, ready, loading } =
    useBusinessAntdAutoComplete({
      form,
      messageApi,
    });

  return (
    <Form.Item name={"name"} rules={rules} label="Search Business">
      <AutoComplete
        options={renderOptions}
        onSelect={(_, option) => handleBusinessSelect(option)}
        onSearch={(searchText) => setValue(searchText)}
        placeholder="Type to search places"
        disabled={!ready}
      >
        <Input.Search loading={loading} />
      </AutoComplete>
    </Form.Item>
  );
};

export default BusinessAntdAutoComplete;
