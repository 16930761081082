import { Navigate } from "react-router-dom";

import { MODULES, ACTIONS } from "global/constants";
import { WithPermission, WithLayout } from "global/hoc";
import { PageLayout, AuthLayout, DefaultLayout } from "global/layouts";
import {
  LoginPage,
  ReviewPage,
  PageNotFound,
  Businesses,
  Customers,
  Dashboard,
  UnauthorizedBusiness,
} from "pages";
import { ROLES } from "global/constants";
import { Result } from "antd";

const getRoutes = (isAuthenticated, userRole, isUnauthorizedBusiness) => [
  {
    path: "",
    element: (
      <Navigate
        to={userRole === ROLES.ADMIN ? "/portal" : "/portal/customers"}
      />
    ),
  },

  {
    path: "portal",
    element: isAuthenticated ? (
      isUnauthorizedBusiness ? (
        <Navigate to="/unauthorizedBusiness" replace />
      ) : (
        <DefaultLayout />
      )
    ) : (
      <Navigate replace to="/auth" />
    ),
    children: [
      {
        index: true,
        element: (
          <WithPermission
            action={ACTIONS.VIEW}
            module={MODULES.DASHBOARD}
            role={userRole}
            isRouteComponent
          >
            <WithLayout component={<Dashboard />} heading={"Dashboard"} />
          </WithPermission>
        ),
      },
      {
        path: "businesses",
        element: (
          <WithPermission
            action={ACTIONS.VIEW}
            module={MODULES.BUSINESSES}
            role={userRole}
            isRouteComponent
          >
            <WithLayout component={<Businesses />} heading={"Businesses"} />
          </WithPermission>
        ),
      },
      {
        path: "customers",
        element: (
          <WithPermission
            action={ACTIONS.VIEW}
            module={MODULES.CUSTOMERS}
            role={userRole}
            isRouteComponent
          >
            <WithLayout component={<Customers />} heading={"Customers"} />
          </WithPermission>
        ),
      },
    ],
  },
  {
    path: "review",
    element: <PageLayout />,
    children: [
      {
        path: ":businessId",
        element: <ReviewPage />,
      },
      {
        path: "thanks",
        element: (
          <Result
            status={"success"}
            title="You have already reviewed. Thankyou"
          />
        ),
      },
    ],
  },
  {
    path: "auth",
    element: isAuthenticated ? (
      isUnauthorizedBusiness ? (
        <Navigate to={"/unauthorizedBusiness"} replace />
      ) : (
        <Navigate
          replace
          to={userRole === ROLES.ADMIN ? "/portal" : "/portal/customers"}
        />
      )
    ) : (
      <AuthLayout />
    ),
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "unauthorizedBusiness",
    element: !isAuthenticated ? (
      <Navigate to={"/auth"} />
    ) : isUnauthorizedBusiness ? (
      <UnauthorizedBusiness />
    ) : (
      <Navigate to={"/"} />
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default getRoutes;
