import { useMemo } from "react";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

const useBusinessAntdAutoComplete = ({ form, messageApi }) => {
  const {
    ready,
    suggestions: { data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["establishment"], // Only return business establishments
      componentRestrictions: { country: ["US", "AU", "CA"] }
    },
    debounce: 500,
  });

  const handleBusinessSelect = async ({ key }) => {
    try {
      const details = await getDetails({
        placeId: key,
        fields: [
          "formatted_phone_number",
          "international_phone_number",
          "formatted_address",
        ],
      });
      if (details) {
        const address = details.formatted_address;
        const reviewEntryUrl = `search.google.com/local/writereview?placeid=${key}`;

        const fields = { address, reviewEntryUrl };

        if (details.international_phone_number) {
          let intPhoneNumber = details.international_phone_number;
          let [countryCode, phone2] = intPhoneNumber.split(" ");
          fields.countryCode = countryCode;
          fields.phone2 = phone2;
        }

        form.setFieldsValue(fields);

        messageApi.success("Business details retrieved successfully!");
      } else {
        throw new Error("No detailed results found for the given business.");
      }
    } catch (error) {
      console.error("Error fetching business details:", error);
      messageApi.error(
        "Failed to fetch business details. Please try again later."
      );
      form.setFieldsValue({
        address: "",
        reviewEntryUrl: "",
        phone2: "",
        countryCode: "+1",
      });
    }
    clearSuggestions();
  };

  const renderOptions = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((suggestion) => ({
            key: suggestion.place_id,
            value: `${suggestion.structured_formatting.main_text} ${suggestion.structured_formatting.secondary_text}`,
          }))
        : [],
    [data]
  );

  return { handleBusinessSelect, renderOptions, setValue, ready, loading };
};

export default useBusinessAntdAutoComplete;
