import { ACTIONS } from "global/constants";
import { isActionAllowed } from "./withPermission.utils";
import { Result } from "antd";

const WithPermission = ({
  children,
  action,
  module,
  role,
  isRouteComponent = false,
}) => {
  if (!isActionAllowed(role, action, module)) {
    if (action === ACTIONS.VIEW && isRouteComponent) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      );
    }
    return <></>;
  }
  return <>{children}</>;
};

export default WithPermission;
