import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { countryCodePrefixSelector, triggerCopyMessage } from "global/utils";
import { reviewEntryOptions } from "pages/businesses/businesses.constants";
import CopyToClipboard from "react-copy-to-clipboard";
import BusinessAntdAutoComplete from "../businessAntdAutoComplete/businessAntdAutoComplete.component";
import { formRules } from "./manageBusinessDrawer.formRules";
import useManageBusinessDrawer from "./useManageBusinessDrawer.hook";

const ManageBusinessDrawer = ({ open, onClose, selectedBusiness }) => {
  const {
    form,
    handleManageBusiness,
    messageContext,
    messageApi,
    loading,
    smsCouponSubService,
    reviewEntryUrl,
    reviewEntryUrl2,
    reviewEntryUrl3,
    reviewEntryUrl4,
    isAdmin,
    url,
    spaceStyle,
  } = useManageBusinessDrawer({
    onClose,
    selectedBusiness,
    open,
  });

  return (
    <>
      {messageContext}
      <Drawer
        open={open}
        onClose={onClose}
        title={"Business Details"}
        width={600}
        extra={
          <Button
            loading={loading}
            icon={<CheckOutlined />}
            type="primary"
            onClick={form.submit}
          >
            Save
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleManageBusiness}>
          <Row gutter={[20]}>
            <Col xs={24}>
              <BusinessAntdAutoComplete
                form={form}
                rules={formRules.name}
                messageApi={messageApi}
              />
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Business Address"
                name={"address"}
                rules={formRules.address}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Business Phone 1"
                name={"phone1"}
                rules={formRules.phone1}
              >
                <InputNumber
                  addonBefore={countryCodePrefixSelector}
                  className="w-100"
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Business Phone 2"
                name={"phone2"}
                rules={formRules.phone2}
              >
                <InputNumber
                  addonBefore={countryCodePrefixSelector}
                  className="w-100"
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Contact Person"
                name={"contactPerson"}
                rules={formRules.contactPerson}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Email" name={"email"} rules={formRules.email}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Business Active Till Date"
                name="businessActiveTillDate"
              >
                <DatePicker className="w-100" disabled={!isAdmin} />
              </Form.Item>
            </Col>
            {isAdmin && (
              <>
                <Col xs={12}>
                  <Form.Item
                    label="Default Review Url"
                    name="defaultReviewEntryUrl"
                    rules={formRules.defaultReviewEntryUrl}
                  >
                    <Select options={reviewEntryOptions} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Row gutter={[20]}>
                    <Col xs={12}>
                      <label htmlFor="reviewEntryUrl">Review Entry url</label>
                      <Space.Compact style={spaceStyle}>
                        <Form.Item
                          name={"reviewEntryUrl"}
                          rules={formRules.reviewEntryUrl}
                          dependencies={["defaultReviewEntryUrl"]}
                          noStyle
                        >
                          <Input prefix="https://" />
                        </Form.Item>
                        <CopyToClipboard
                          text={reviewEntryUrl}
                          onCopy={triggerCopyMessage}
                        >
                          <Button type="primary" disabled={!reviewEntryUrl}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Space.Compact>
                    </Col>
                    <Col xs={12}>
                      <label htmlFor="reviewEntryUrl2">
                        Review Entry url 2
                      </label>
                      <Space.Compact style={spaceStyle}>
                        <Form.Item
                          name={"reviewEntryUrl2"}
                          rules={formRules.reviewEntryUrl2}
                          dependencies={["defaultReviewEntryUrl"]}
                          noStyle
                        >
                          <Input prefix="https://" />
                        </Form.Item>
                        <CopyToClipboard
                          text={reviewEntryUrl2}
                          onCopy={triggerCopyMessage}
                        >
                          <Button type="primary" disabled={!reviewEntryUrl2}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Space.Compact>
                    </Col>
                    <Col xs={12}>
                      <label htmlFor="reviewEntryUrl3">
                        Review Entry url 3
                      </label>
                      <Space.Compact style={spaceStyle}>
                        <Form.Item
                          name={"reviewEntryUrl3"}
                          rules={formRules.reviewEntryUrl3}
                          dependencies={["defaultReviewEntryUrl"]}
                          noStyle
                        >
                          <Input prefix="https://" />
                        </Form.Item>
                        <CopyToClipboard
                          text={reviewEntryUrl3}
                          onCopy={triggerCopyMessage}
                        >
                          <Button type="primary" disabled={!reviewEntryUrl3}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Space.Compact>
                    </Col>
                    <Col xs={12}>
                      <label htmlFor="reviewEntryUrl4">
                        Review Entry url 4
                      </label>
                      <Space.Compact style={spaceStyle}>
                        <Form.Item
                          name={"reviewEntryUrl4"}
                          rules={formRules.reviewEntryUrl4}
                          dependencies={["defaultReviewEntryUrl"]}
                          noStyle
                        >
                          <Input prefix="https://" />
                        </Form.Item>
                        <CopyToClipboard
                          text={reviewEntryUrl4}
                          onCopy={triggerCopyMessage}
                        >
                          <Button type="primary" disabled={!reviewEntryUrl4}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Space.Compact>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <label htmlFor="businessUrl">Business Url</label>
                  <Space.Compact style={spaceStyle}>
                    <Input value={url} disabled />

                    <CopyToClipboard text={url} onCopy={triggerCopyMessage}>
                      <Button type="primary">Copy</Button>
                    </CopyToClipboard>
                  </Space.Compact>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Customer Visits" name="customerVisits">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </>
            )}

            {isAdmin && (
              <Col xs={24}>
                <Form.Item
                  label="Notes/Comments"
                  name="notes"
                  rules={formRules.notes}
                >
                  <TextArea />
                </Form.Item>
              </Col>
            )}

            <Col xs={12}>
              <Form.Item
                label="Sms Coupon Subscription Service"
                name={"smsCouponSubService"}
                rules={formRules.smsCouponSubService}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  disabled={!isAdmin}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Sms Subscription Expiry Date"
                name="smsSubExpiry"
                rules={formRules.smsSubExpiry}
                dependencies={["smsCouponSubService"]}
              >
                <DatePicker className="w-100" disabled={!isAdmin} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Collect Customer Info"
                name={"collectCustomerInfo"}
                rules={formRules.collectCustomerInfo}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  disabled={!isAdmin}
                />
              </Form.Item>
            </Col>
          </Row>
          {smsCouponSubService && (
            <>
              <Divider>Coupon</Divider>
              <Row>
                <Col xs={24}>
                  <Form.Item
                    label="Last send to all date"
                    name={"lastSendToAllDate"}
                  >
                    <DatePicker
                      disabled
                      className="w-100"
                      placeholder="No Date"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Coupon Message"
                    name="couponMessage"
                    rules={formRules.couponMessage}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default ManageBusinessDrawer;
